// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDWevKqTYwhoRIVdLlwJizjdt2sxGu_dAA",
  authDomain: "prime-infra-f958b.firebaseapp.com",
  projectId: "prime-infra-f958b",
  storageBucket: "prime-infra-f958b.appspot.com",
  messagingSenderId: "158689354484",
  appId: "1:158689354484:web:2480124749bc11172ff4a1",
  measurementId: "G-VFQ3R6WLWF"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Export initialized services
export const auth = getAuth(app);
export const db = getFirestore(app);
