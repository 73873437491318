import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Row,
  Col,
  message,
  Modal,
  Select,
  Input,
  Form,
} from "antd";
import { db } from "../firebase/firebase"; // Import initialized Firestore instance
import { collection, getDocs } from "firebase/firestore";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Loader from "../components/Loader";
import { InboxOutlined } from "@ant-design/icons";
const { Meta } = Card;
const { Option } = Select;

const Report_list = () => {
  const [reportList, setReportList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [rate, setRate] = useState(0);
  const [unit, setUnit] = useState("SQFT");
  const [total, setTotal] = useState(0);

  // Fetch reports, clients, and materials
  const fetchData = async () => {
    setLoading(true);
    try {
      // Fetch reports
      const reportsSnapshot = await getDocs(collection(db, "report_list"));
      const reportsData = reportsSnapshot.docs.map((doc) => ({
        _id: doc.id, // Add the _id field
        id: doc.id, // You can keep the id if you need it separately
        ...doc.data(),
      }));
      setReportList(reportsData);

      // Fetch clients
      const clientsSnapshot = await getDocs(collection(db, "client"));
      const clientsData = clientsSnapshot.docs.map((doc) => ({
        _id: doc.id, // Add the _id field
        ...doc.data(),
      }));
      setClientList(clientsData);

      // Fetch materials
      const materialsSnapshot = await getDocs(collection(db, "Material"));
      const materialsData = materialsSnapshot.docs.map((doc) => ({
        _id: doc.id, // Add the _id field
        ...doc.data(),
      }));
      setMaterialList(materialsData);
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle modal visibility and reset
  const handleCardClick = () => {
    setModalVisible(true);
  };

  const handleReset = () => {
    setSelectedClient(null);
    setSelectedMaterial(null);
    setQuantity(0);
    setRate(0);
    setUnit("SQFT");
    setTotal(0);
  };

  const handleAddMaterial = () => {
    message.success("Material added!");
  };

  const handleSave = () => {
    message.success("Material details saved successfully!");
    setModalVisible(false);
  };

  const handleQuantityChange = (e) => {
    const qty = e.target.value;
    setQuantity(qty);
    setTotal(qty * rate);
  };

  const handleRateChange = (e) => {
    const rateValue = e.target.value;
    setRate(rateValue);
    setTotal(quantity * rateValue);
  };

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-10 bg-light">
            <div className="main m-lg-4 mx-2">
              <div className="overview mt-3 px-3 py-3 bg-white">
                <Row gutter={[16, 16]}>
                  {/* Material Sections */}
                  {["Quotations Material"].map((section, index) => (
                    <Col span={12} key={index}>
                      <Card
                        title={section}
                        bordered={false}
                        bodyStyle={{ padding: "20px" }}
                      >
                        <Row gutter={[16, 16]}>
                          {[
                            "New Material",
                            "Used Material",
                            "Rental Quotation",
                            "Scaffolding Work Quotations",
                          ].map((item, idx) => (
                            <Col span={12} key={idx}>
                              <Card
                                hoverable
                                onClick={handleCardClick}
                                cover={
                                  <InboxOutlined
                                    style={{
                                      fontSize: "50px",
                                      marginTop: "20px",
                                    }}
                                  />
                                }
                              >
                                <Meta title={item} className="text-center" />
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Card>
                    </Col>
                  ))}
                  {["Delivery Challan"].map((section, index) => (
                    <Col span={12} key={index}>
                      <Card
                        title={section}
                        bordered={false}
                        bodyStyle={{ padding: "20px" }}
                      >
                        <Row gutter={[16, 16]}>
                          {[
                            "Delivery Challan",
                            "Returnable Delivery Challan",
                          ].map((item, idx) => (
                            <Col span={12} key={idx}>
                              <Card
                                hoverable
                                onClick={handleCardClick}
                                cover={
                                  <InboxOutlined
                                    style={{
                                      fontSize: "50px",
                                      marginTop: "20px",
                                    }}
                                  />
                                }
                              >
                                <Meta title={item} className="text-center" />
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {loading && <Loader />}

      {/* Material Details Modal */}
      <Modal
        title="Material Details"
        visible={modalVisible}
        width={"1000px"}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="reset" onClick={handleReset}>
            Reset
          </Button>,
          <Button key="addMaterial" type="primary" onClick={handleAddMaterial}>
            Add Material
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
      >
        <Form layout="vertical">
          {/* Client, Material, and Date Fields */}
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Client">
                <Select
                  value={selectedClient}
                  onChange={(value) => setSelectedClient(value)}
                  placeholder="Select Client"
                >
                  {clientList.map((client) => (
                    <Option key={client._id} value={client.name}>
                      {client.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Material">
                <Select
                  value={selectedMaterial}
                  onChange={(value) => setSelectedMaterial(value)}
                  placeholder="Select Material"
                >
                  {materialList.map((material) => (
                    <Option key={material.id} value={material.id}>
                      {material.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Date">
                <Input type="date" />
              </Form.Item>
            </Col>
          </Row>

          {/* Material Details: Name, Unit, Quantity */}
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Material Name">
                <Input value={selectedMaterial} disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Unit">
                <Select value={unit} onChange={setUnit}>
                  {["SQFT", "CFT", "NOS", "METER", "KG"].map((unit) => (
                    <Option key={unit} value={unit}>
                      {unit}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Quantity">
                <Input
                  value={quantity}
                  onChange={handleQuantityChange}
                  type="number"
                  placeholder="Enter Quantity"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Rate and Total Fields */}
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Rate">
                <Input
                  value={rate}
                  onChange={handleRateChange}
                  type="number"
                  placeholder="Enter Rate"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Total">
                <Input value={total} disabled />
              </Form.Item>
            </Col>

            {/* Client Info */}
            <Col span={8}>
              {selectedClient && (
                <div>
                  <p>
                    Name:{" "}
                    {
                      clientList.find(
                        (client) => client.name === selectedClient
                      )?.name
                    }
                  </p>
                  <p>
                    Address:{" "}
                    {
                      clientList.find(
                        (client) => client.name === selectedClient
                      )?.address
                    }
                  </p>
                  <p>
                    Mobile:{" "}
                    {
                      clientList.find(
                        (client) => client.name === selectedClient
                      )?.contact_number
                    }
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Report_list;
