import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Switch, Form, Input, message } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase/firebase"; // Import initialized Firestore instance
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Loader from "../components/Loader";

const { confirm } = Modal;

const Clientlist = () => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editData, setEditData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch clients from Firestore
  const fetchClients = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "client"));
      const clientsData = querySnapshot.docs.map((doc) => ({
        _id: doc.id,
        ...doc.data(),
      }));
      setClients(clientsData);
    } catch (error) {
      console.error("Error fetching clients:", error);
      message.error("Failed to fetch clients.");
    } finally {
      setLoading(false);
    }
  };

  // Handle add or edit client
  const handleSaveEdit = async (editedData) => {
    const clientData = {
      name: editedData.name,
      contact_number: editedData.contact_number,
      address: editedData.address,
      isActive: editedData.isActive,
      createdAt: editData.createdAt || new Date(), // Use created date if editing
    };

    try {
      if (editData._id) {
        // Update existing client
        await updateDoc(doc(db, "client", editData._id), clientData);
        message.success("Client updated successfully.");
      } else {
        // Add new client
        await addDoc(collection(db, "client"), clientData);
        message.success("Client added successfully.");
      }
      fetchClients();
    } catch (error) {
      console.error("Error saving client:", error);
      message.error("Failed to save client.");
    } finally {
      setEditModalVisible(false);
    }
  };

  // Handle client deletion
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "client", id));
      message.success("Client deleted successfully.");
      fetchClients();
    } catch (error) {
      console.error("Error deleting client:", error);
      message.error("Failed to delete client.");
    }
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure you want to delete this client?",
      icon: <DeleteFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record._id);
      },
    });
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "Mobile Number",
      dataIndex: "contact_number",
      key: "contact_number",
      width: "25%",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "30%",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      width: "10%",
      render: (isActive, record) => (
        <Switch
          checked={isActive}
          size="small"
          onChange={(checked) => handleSaveEdit({ ...record, isActive: checked })}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Button
            type="primary"
            size="small"
            onClick={() => handleEdit(record)}
            className="bg-white text-dark shadow-none"
          >
            <i className="fa-regular fa-pen-to-square " />
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => showDeleteConfirm(record)}
            className="bg-white text-dark shadow-none"
          >
            <i className="fa-solid fa-trash-can " />
          </Button>
        </>
      ),
    },
  ];

  const EditModal = ({ visible, data, onClose, onSave }) => {
    const [form] = Form.useForm();

    const handleSave = () => {
      form
        .validateFields()
        .then((values) => {
          onSave(values);
          form.resetFields();
          onClose();
        })
        .catch((errorInfo) => {
          console.log("Validation Failed:", errorInfo);
        });
    };

    return (
      <Modal
        title="Client"
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
      >
        <Form form={form} initialValues={data}>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter client name" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="contact_number" label="Mobile Number" rules={[{ required: true, message: "Please enter mobile number" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="address" label="Address" rules={[{ required: true, message: "Please enter address" }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="isActive" label="Active" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const handleEdit = (record) => {
    setEditData(record);
    setEditModalVisible(true);
  };

  const filteredClients = clients.filter((client) =>
    client.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-10 bg-light">
            <div className="main m-lg-4 mx-2">
              <div className="overview mt-3 px-3 py-3 bg-white">
              <div className="row d-flex justify-content-between m-3">
              <Input
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="mb-3 col-md-4"
                  />
                  <Button
                    type="primary add-button col-md-2"
                    onClick={() => handleEdit({ name: "", contact_number: "", address: "", isActive: true })}
                  >
                    Add Client
                  </Button>
                </div>

                <Table
                  dataSource={filteredClients}
                  columns={columns}
                  loading={loading}
                  pagination={{ pageSize: window.innerWidth < 768 ? 8 : 10 }} // Smaller page size on mobile
                  size={window.innerWidth < 768 ? 'small' : 'middle'} // Compact table for mobile
                  scroll={{ x: 600 }} // Enables horizontal scroll on small screens if needed
                />
                <EditModal
                  visible={editModalVisible}
                  data={editData}
                  onClose={() => setEditModalVisible(false)}
                  onSave={handleSaveEdit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* {loading && <Loader />} */}
    </>
  );
};

export default Clientlist;
