import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Switch, Form, Input, message } from "antd";
import { UploadOutlined, DeleteFilled } from "@ant-design/icons";
import {
  collection,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase"; // Import initialized Firestore instance
import moment from "moment";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Loader from "../components/Loader";

const { confirm } = Modal;

const Category = () => {
  const [file, setFile] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editData, setEditData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [Material, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch Material from Firestore
  const fetchCategories = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "Material"));
      const categoriesData = querySnapshot.docs.map((doc) => ({
        _id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching Material:", error);
      message.error("Failed to fetch Material.");
    } finally {
      setLoading(false);
    }
  };

  // Handle add or edit category
  const handleSaveEdit = async (editedData) => {
    const categoryData = {
      name: editedData.name,
      description: editedData.description,
      isActive: editedData.isActive,
      stock: editedData.stock, // Add stock field
      qty: editedData.qty, // Add qty field
      createdAt: editData.createdAt || new Date(), // Use created date if editing
    };

    try {
      if (editData._id) {
        // Update existing category
        await updateDoc(doc(db, "Material", editData._id), categoryData);
        message.success("Category updated successfully.");
      } else {
        // Add new category
        await addDoc(collection(db, "Material"), categoryData);
        message.success("Category added successfully.");
      }
      fetchCategories();
    } catch (error) {
      console.error("Error saving category:", error);
      message.error("Failed to save category.");
    } finally {
      setEditModalVisible(false);
    }
  };

  // Handle category deletion
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "Material", id));
      message.success("Category deleted successfully.");
      fetchCategories();
    } catch (error) {
      console.error("Error deleting category:", error);
      message.error("Failed to delete category.");
    }
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure you want to delete this category?",
      icon: <DeleteFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record._id);
      },
    });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "25%",
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      width: "15%",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: "15%",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      width: "10%",
      render: (isActive, record) => (
        <Switch
          checked={isActive}
          size="small"
          onChange={(checked) =>
            handleSaveEdit({ ...record, isActive: checked })
          }
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: "10%",
      render: (record) => (
        <>
          <Button
            type="primary"
            size="small"
            onClick={() => handleEdit(record)}
            className="bg-white text-dark shadow-none"
          >
            <i className="fa-regular fa-pen-to-square " />
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => showDeleteConfirm(record)}
            className="bg-white text-dark shadow-none"
          >
            <i className="fa-solid fa-trash-can " />
          </Button>
        </>
      ),
    },
  ];

  const EditModal = ({ visible, data, onClose, onSave }) => {
    const [form] = Form.useForm();

    const handleSave = () => {
      form
        .validateFields()
        .then((values) => {
          onSave(values);
          form.resetFields();
          onClose();
        })
        .catch((errorInfo) => {
          console.log("Validation Failed:", errorInfo);
        });
    };

    return (
      <Modal
        title="Category"
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
      >
        <Form form={form} initialValues={data}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter category name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="stock"
            label="Stock"
            rules={[{ required: true, message: "Please enter stock quantity" }]}
          >
            <Input  />
          </Form.Item>
          <Form.Item
            name="qty"
            label="Quantity"
            rules={[{ required: true, message: "Please enter quantity" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="isActive" label="Active" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const handleEdit = (record) => {
    setEditData(record);
    setEditModalVisible(true);
  };

  const filteredCategories = Material.filter((category) =>
    category.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-10 bg-light">
            <div className="main m-lg-4 mx-2">
              <div className="overview mt-3 px-3 py-3 bg-white">
                <div className="row d-flex justify-content-between m-3">
                  <Input
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="mb-3 col-md-4"
                  />
                  <Button
                    type="primary add-button col-md-2"
                    onClick={() =>
                      handleEdit({
                        name: "",
                        description: "",
                        stock: "",
                        qty: 0,
                        isActive: true,
                      })
                    }
                  >
                    Add Material
                  </Button>
                </div>

                <Table
  dataSource={filteredCategories}
  columns={columns}
  loading={loading}
  pagination={{ pageSize: window.innerWidth < 768 ? 8 : 10 }} // Smaller page size on mobile
  size={window.innerWidth < 768 ? 'small' : 'middle'} // Compact table for mobile
  scroll={{ x: 600 }} // Enables horizontal scroll on small screens if needed
/>
                <EditModal
                  visible={editModalVisible}
                  data={editData}
                  onClose={() => setEditModalVisible(false)}
                  onSave={handleSaveEdit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* {loading && <Loader />} */}
    </>
  );
};

export default Category;
