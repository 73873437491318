import React from "react";
import { Link, useLocation } from "react-router-dom";
import './slider.css';


const Sidebar = () => {
  const location = useLocation();
  const [selectedCountry, setSelectedCountry] = React.useState("IND");

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const logoutFunction = () => {
    localStorage.removeItem("userId");
    window.location.reload();
    window.location.href = "/";
  };

  return (
    <>
      <div className=" col-md-2 py-5 d-lg-block d-none">
        <ul className="nav text-start">
          {/* <li className="nav-item nav-it">
            <Link
              className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 mb-3 w-100 ${location.pathname === "/" ? "active" : ""
                }`}
              to="/"
            >
              <img src="assets/images/dashboard.png" className="mx-3" />
              Dashboard
            </Link>
          </li>
          <li className="nav-item nav-it">
            <Link
              className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 mb-3 ${location.pathname === "/orders" ? "active" : ""
                }`}
              to="/orders"
            >
              <img src="assets/images/order.png" className="mx-3" />
              Orders
            </Link>
          </li> */}
          <li className="nav-item nav-it">
            <Link
              className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 mb-3 ${location.pathname === "/products" ? "active" : ""
                }`}
              to="/products"
            >
              <img src="assets/images/product.png" className="mx-3" />
              Quotations
            </Link>
          </li>
          <li className="nav-item nav-it">
            <Link
              className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 mb-3 ${location.pathname === "/category" ? "active" : ""
                }`}
              to="/category"
            >
              <img src="assets/images/brand.png" className="mx-3" />
              Material
            </Link>
          </li>
          <li className="nav-item nav-it">
            <Link
              className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 mb-3 ${location.pathname === "/coupons" ? "active" : ""
                }`}
              to="/coupons"
            >
              <img src="assets/images/brand.png" className="mx-3" />
              Client
            </Link>
          </li>
          <li className="nav-item nav-it">
            <Link
              className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 mb-3 ${location.pathname === "/brand" ? "active" : ""
                }`}
              to="/brand"
            >
              <img src="assets/images/brand.png" className="mx-3" />
              Report List
            </Link>
          </li>
         
          {/* <li className="nav-item nav-it">
            <Link
              className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 mb-3 ${location.pathname === "/customer" ? "active" : ""
                }`}
              to="/customer"
            >
              <img src="assets/images/customer.png" className="mx-3" />
              Customer
            </Link>
          </li> */}
          {/* <li className="nav-item">
            <Link
              className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 mb-3 ${
                location.pathname === "/staff" ? "active" : ""
              }`}
              to="/staff"
            >
              <img src="assets/images/staff.png" className="mx-3" />
              Our Staff
            </Link>
          </li> */}


          {/* <li className="nav-item nav-it">
            <Link
              className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 mb-3 ${location.pathname === "" ? "active" : ""
                }`}
              to="/blog"
            >
              <img src="assets/images/inter.png" className="mx-3" />
              Blog
            </Link>
           
          </li> */}
          {/* <li className="nav-item nav-it">
            <Link
              className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 mb-3 ${location.pathname === "/settings" ? "active" : ""
                }`}
              to="/settings"
            >
              <img src="assets/images/setting.png" className="mx-3" />
              Setting
            </Link>
          </li> */}

          <li className="nav-item text-center ps-3">
            <a
              className={`nav-link btn btn-sm my-3 text-light bg-main rounded-pill text-decoration-none fw-semibold btn-sm  fs-6 mb-3`}
              onClick={logoutFunction}
            >
              Logout
            </a>
          </li>
        </ul>
      </div>

      <div className="d-block d-lg-none px-0">
        <section className="top-nav px-4" >
          <div>
            Admin
          </div>
          <input id="menu-toggle" type="checkbox" />
          <label className='menu-button-container' for="menu-toggle">
            <div className='menu-button'></div>
          </label>
          <ul className="menu">
            {/* <li>
              <Link
                className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 d-block my-auto w-100 ${location.pathname === "/" ? "active" : ""
                  }`}
                to="/"
              >
                <img src="assets/images/dashboard.png" className="mx-3" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 d-block my-auto ${location.pathname === "/orders" ? "active" : ""
                  }`}
                to="/orders"
              >
                <img src="assets/images/order.png" className="mx-3" />
                Orders
              </Link>
            </li> */}
             <li>
              <Link
                className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 d-block my-auto ${location.pathname === "/products" ? "active" : ""
                  }`}
                to="/products"
              >
                <img src="assets/images/product.png" className="mx-3" />
                Quotations
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 d-block my-auto ${location.pathname === "/category" ? "active" : ""
                  }`}
                to="/category"
              >
                <img src="assets/images/brand.png" className="mx-3" />
                Material
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 d-block my-auto ${location.pathname === "/brand" ? "active" : ""
                  }`}
                to="/coupons"
              >
                <img src="assets/images/brand.png" className="mx-3" />
                Client
              </Link>
            </li>
           

            <li>
              <Link
                className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 d-block my-auto ${location.pathname === "/customer" ? "active" : ""
                  }`}
                to="/brand"
              >
                <img src="assets/images/customer.png" className="mx-3" />
                Report List
              </Link>
            </li>
            {/* <li>
              <Link
                className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 d-block my-auto ${location.pathname === "" ? "active" : ""
                  }`}
                to="/blog"
              >
                <img src="assets/images/inter.png" className="mx-3" />
                Blog
              </Link>
            </li> */}

            <li>
              <Link
                className={`nav-link  text-decoration-none fw-semibold  text-secondary fs-6 d-block my-auto ${location.pathname === "/settings" ? "active" : ""
                  }`}
                to="/settings"
              >
                <img src="assets/images/setting.png" className="mx-3" />
                Setting
              </Link>
            </li>
            <li>
              <a
                className={`nav-link btn btn-sm my-3 text-light bg-main rounded-pill text-decoration-none fw-semibold btn-sm  fs-6 mb-3`}
                onClick={logoutFunction}
              >
                Logout
              </a>
            </li>

          </ul>
        </section>
      </div>


    </>
  );
};

export default Sidebar;