import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Row,
  Col,
  Popover,
  Badge,
  Select,
  notification,
  TimePicker,
  Dropdown,
  Menu,
} from "antd";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { OrderUpdate, OrderUserList, OrderRefresh } from "../reducer/thunks";
import moment from "moment";
import constant from "../constant/constant";
import ExportCSV from "../components/Excelexport";
import Loader from "../components/Loader";
// import io from "socket.io-client";
import {
  MessageOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  SmileOutlined,
  DownOutlined
} from "@ant-design/icons";
import { useEventContext } from "../components/EventProvider";

const { Option } = Select;
const Orders = () => {
  const eventEmitter = useEventContext();

  document.title = " Prime Infra";
  document.getElementsByTagName("META")[2].content = " Prime Infra";
  const [searchText, setSearchText] = useState("");

  // Define state variables for start and end times
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(""); // State to track the selected date range

  // Handle change for start time
  const handleStartTimeChange = (time, timeString) => {
    setStartTime(time);
  };

  // Handle change for end time
  const handleEndTimeChange = (time, timeString) => {
    setEndTime(time);
  };



  let userId = localStorage.getItem("userId");

  const dispatch = useDispatch();

  const {
    loading: getOrderUserLoading,
    Ordererror: getOrderUserError,
    getOrder: getOrderResponse,
  } = useSelector((state) => state.getOrder);
  const { Orderlength } = useSelector((state) => state.Orderlength);
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(OrderUserList());


      // if (
      //   Orderlength > getOrderResponse?.orders?.length &&
      //   getOrderResponse?.orders !== undefined
      // ) {
      //   notificationSound(
      //     getOrderResponse?.orders[0].products[0],
      //     getOrderResponse?.orders[0]
      //   );
      // }
      setFilteredDataSource(getOrderResponse?.orders || []);
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Get start and end dates for the selected date range
    let startDate, endDate;
    const today = moment().startOf('day');

    switch (selectedDateRange) {
        case 'today':
            startDate = today;
            endDate = moment().endOf('day');
            break;
        case 'yesterday':
            startDate = moment().subtract(1, 'day').startOf('day');
            endDate = moment().subtract(1, 'day').endOf('day');
            break;
        case 'last7days':
            startDate = moment().subtract(6, 'days').startOf('day');
            endDate = moment().endOf('day');
            break;
        case 'last30days':
            startDate = moment().subtract(29, 'days').startOf('day');
            endDate = moment().endOf('day');
            break;
        default:
            startDate = null;
            endDate = null;
    }

    // Filter orders based on the selected date range
    const filteredOrder = getOrderResponse?.orders.filter(order => {
        // Check if order date is within the selected date range
        const orderDate = moment(order.createdAt);
        const withinDateRange = (!startDate || orderDate.isSameOrAfter(startDate)) &&
            (!endDate || orderDate.isSameOrBefore(endDate));

        return withinDateRange;
    }) || []; // Ensure filteredOrder is an array, even if empty

    // Apply search filter if searchText is not empty
    const searchResults = searchText.trim() === "" ? filteredOrder : filteredOrder.filter(order =>
        order.user.firstname.toLowerCase().includes(searchText.toLowerCase())
    );

    // Update filtered orders state accordingly
    setFilteredDataSource(searchResults);
}, [selectedDateRange, searchText, getOrderResponse]);



  const filterOrders = (dateRange) => {
    // Get start and end dates for the selected date range
    let startDate, endDate;
    const today = moment().startOf('day');

    switch (dateRange) {
      case 'today':
        startDate = today;
        endDate = moment().endOf('day');
        break;
      case 'yesterday':
        startDate = moment().subtract(1, 'day').startOf('day');
        endDate = moment().subtract(1, 'day').endOf('day');
        break;
      case 'last7days':
        startDate = moment().subtract(6, 'days').startOf('day');
        endDate = moment().endOf('day');
        break;
      case 'last30days':
        startDate = moment().subtract(29, 'days').startOf('day');
        endDate = moment().endOf('day');
        break;
      default:
        startDate = null;
        endDate = null;
    }

    // Filter orders based on the selected date range
    const filteredOrder = getOrderResponse?.orders.filter(order => {
      // Check if order date is within the selected date range
      const orderDate = moment(order.createdAt);
      const withinDateRange = (!startDate || orderDate.isSameOrAfter(startDate)) &&
        (!endDate || orderDate.isSameOrBefore(endDate));

      return withinDateRange;
    }) || []; // Ensure filteredOrder is an array, even if empty

    console.log(filteredOrder);

    // Update filtered orders state accordingly
    setFilteredDataSource(filteredDataSource || []);
  };




  const handleDateRangeChange = ({ key }) => {
    setSelectedDateRange(key);
    filterOrders(key);
  };

  const notificationSound = (id, order) => {
    notification.open({
      message: id?.name,
      description: `${id?.name} Total Kg ${id.weight} Payment status ${order.paymentStatus} Total Amount ${order.totalAmount}`,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
    const audio = new Audio(require("../constant/songs/notificationsound.wav"));
    audio.load();
    audio
      .play()
      .then(() => {
        console.log("Notification sound played successfully.");
      })
      .catch((error) => {
        console.error("Error playing notification sound:", error);
      });
  };

  const columns = [
    {
      title: "#Order Id",
      dataIndex: "_id",
      key: "_id",
      render: (text) => text.slice(-6),
    },
    {
      title: "Name",
      dataIndex: ["user", "firstname"],
      key: "name",
    },
    {
      title: "Order Product",
      dataIndex: "products",
      key: "products",
      render: (products) => products.map((product) => product?.name).join(", "),
    },
    {
      title: "Price",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Start Date",
      dataIndex: "createdAt",
      key: "startDate",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "time",
      render: (date) => moment(date).format("HH:mm"),
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      key: "status",
      render: (status, record) => (
        <Select
          defaultValue={status}
          style={{ width: 130 }}
          onChange={(newStatus) => UpdateStatus(newStatus, record)}
        >
          <Option key="order_placed" value="Order Placed" className="cyan-text">
            Order Placed
          </Option>
          <Option key="confirmed" value="Confirmed" className="cyan-text">
            Confirmed
          </Option>
          <Option key="preparing" value="Preparing" className="cyan-text">
            Preparing
          </Option>
          <Option key="processing" value="Processing" className="cyan-text">
            Processing
          </Option>
          <Option
            key="out_for_delivery"
            value="Out for Delivery"
            className="purple-text"
          >
            Out for Delivery
          </Option>
          <Option key="delivered" value="Delivered" className="cyan-text">
            Delivered
          </Option>
          <Option key="cancelled" value="Cancelled" className="red-text">
            Cancelled
          </Option>
          <Option key="refunded" value="Refunded" className="orange-text">
            Refunded
          </Option>
          <Option key="on-hold" value="On Hold" className="brown-text">
            On Hold
          </Option>
        </Select>
      ),
    },

    {
      title: "Message",
      dataIndex: "exta_message",
      key: "exta_message",
      render: (status) => (
        <Popover
          content={
            <div>
              <p>{status}</p>
            </div>
          }
          title="Message Request"
        >
          <Badge count={status ? 1 : 0}>
            <MessageOutlined style={{ fontSize: "22px", color: "#08c" }} />
          </Badge>
        </Popover>
      ),
    },


    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <Button
          size="small"
          onClick={() => handleViewMore(record)}
          className="View-text"
        >
          View More
        </Button>
      ),
    },
  ];

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const handleSearch = (value, DateRange) => {
    // Get start and end dates for the selected date range
    let startDate, endDate;
    const today = moment().startOf('day');

    switch (DateRange) {
      case 'today':
        startDate = today;
        endDate = moment().endOf('day');
        break;
      case 'yesterday':
        startDate = moment().subtract(1, 'day').startOf('day');
        endDate = moment().subtract(1, 'day').endOf('day');
        break;
      case 'last7days':
        startDate = moment().subtract(6, 'days').startOf('day');
        endDate = moment().endOf('day');
        break;
      case 'last30days':
        startDate = moment().subtract(29, 'days').startOf('day');
        endDate = moment().endOf('day');
        break;
      default:
        startDate = null;
        endDate = null;
    }
    // Format the startTime and endTime into time strings
    const startTimeString = startTime ? startTime.format("HH:mm") : '';
    const endTimeString = endTime ? endTime.format("HH:mm") : '';
    console.log(startTimeString, endTimeString, "timeString");

    setSearchText(value);
    // Filter the dataSource based on the search text and time range
    // setFilteredDataSource(
    //   getOrderResponse?.orders.filter((order) => {
    //     const lowerCaseValue = value.toLowerCase();

    //     // Check if any product name in the order matches the search value
    //     const productMatch = order.products.some((product) =>
    //       product.name.toLowerCase().includes(lowerCaseValue)
    //     );

    //     // Check if any user details in the order matches the search value
    //     const userMatch =
    //       order.user.firstname.toLowerCase().includes(lowerCaseValue) ||
    //       order.user.lastname.toLowerCase().includes(lowerCaseValue) ||
    //       order.user.mobilenumber.includes(value) || // No need to convert to lowercase for mobile number
    //       order.user.email.toLowerCase().includes(lowerCaseValue);

    //     // // Check if the order was placed within the specified time range
    //     // const orderPlacedWithinTimeRange =
    //     //   (!startTime || moment(order.createdAt).isSameOrAfter(startTime, 'day')) &&
    //     //   (!endTime || moment(order.createdAt).isSameOrBefore(endTime, 'day'));

    //     // Filter orders based on the selected date range
    //     const filteredOrderlist = getOrderResponse?.orders.filter(order => {
    //       // Check if order date is within the selected date range
    //       const orderDate = moment(order.createdAt);
    //       const withinDateRange = (!startDate || orderDate.isSameOrAfter(startDate)) &&
    //         (!endDate || orderDate.isSameOrBefore(endDate));

    //       return withinDateRange;
    //     }) || []; // Ensure filteredOrder is an array, even if empty

    //     console.log(filteredOrderlist);

    //     // Update filtered orders state accordingly

    //     return filteredOrderlist;
    //   })
    // );
  };


  const handleViewMore = (record) => {
    setSelectedOrder(record);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const UpdateStatus = (id, data) => {
    console.log(id, data);
    let bodyparms = { status: id };
    dispatch(OrderUpdate(data._id, bodyparms));
  };

  function getQty(id) {
    // Find the product in the array based on the product ID
    const productqty = selectedOrder?.quantity?.find(
        (item) => item.productId === id
    );
    
    // Check if the product was found
    if (productqty) {
        // Return the quantity of the found product
        return productqty.quantity;
    } else {
        // If the product is not found, return a default value (e.g., 0)
        // or handle the case as needed
        return 0;
    }
}
const shippingamount = () => {
  const calculatedAmount = parseFloat(selectedOrder?.totalAmount).toFixed(0);

  if (450 > +calculatedAmount) {
    return `${calculatedAmount} +(DC) 50 =`+ " " + Number(+calculatedAmount + 50);
  } else {
    return "Delivery Fee";
  }
};

  const modalContent = (
    <div className="p-5 ">
      <p>
        <strong>Customer Name:</strong> {selectedOrder?.user?.firstname}
      </p>
      <p>
        <strong>Address:</strong> {selectedOrder?.address?.street}
      </p>
      <p>
        <strong>Phone Number:</strong> {selectedOrder?.address?.phone}
      </p>
      <p>
        <strong>PIN Code:</strong> {selectedOrder?.address?.pinCode}
      </p>
      <p>
        <strong>State:</strong> {selectedOrder?.address?.state + " "+ selectedOrder?.address?.city}
      </p>

   
      <p className="mb-0">&nbsp;</p>
      {selectedOrder?.products.map((product) => (
        <Row key={product._id} gutter={[16, 16]}>
          <Col span={4}>
            <img
              src={`${product.images[0]}`}
              alt={product.title}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Col>

          <Col span={12}>
            <p>
              <strong></strong> {product?.name}
            </p>

            <p>
              <strong>Delivery Status:</strong> {selectedOrder.delivered_type === "1" ? "Card" : "COD"}
            </p>
            <p>
              <strong>Payment Mode:</strong> {selectedOrder.delivery}
            </p>
          </Col>
          <Col span={8}>
          <Row>
              <Col span={12}>
                <p className="text-start">
                  <strong>Quantity:</strong>
                </p>
              </Col>
              <Col span={12}>
                <p className="text-end">
                  {product?._id && getQty(product?._id)}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <p className="text-start">
                  <strong>Sub-total:</strong>
                </p>
              </Col>
              <Col span={12}>
                <p className="text-end">
                ₹{product.amount}
                </p>
              </Col>
            </Row>
            
            <Row>
              <Col span={12}>
                <p className="text-start">
                  <strong>Shipping fee:</strong>
                </p>
              </Col>
              <Col span={12}>
                <p className="text-end">
                  {shippingamount}
                </p>
              </Col>
            </Row>




          </Col>

        </Row>
      ))}
      <Row className="bg-white">
        <Col span={12}>
          <p className="text-start py-3 ps-3">
            <strong>Total Order Amount:</strong>
          </p>
        </Col>
        <Col span={12}>
          <p className="text-end py-3 fs-4 fw-bold">
          ₹{selectedOrder?.totalAmount}
          </p>
        </Col>
      </Row>

    </div>
  );

  // const filteredOrders = getOrderResponse?.orders
  //   ? getOrderResponse.orders.filter((order) =>
  //     order.user.firstname.toLowerCase().includes(searchText.toLowerCase())
  //   )
  //   : [];

  const wscols = filteredDataSource && filteredDataSource.length > 0 && [
    {
      wch: Math.max(...filteredDataSource.map((customer) => customer._id.length)),
    },
    {
      wch: Math.max(
        ...filteredDataSource.map((customer) => customer.user.firstname.length)
      ),
    },
    {
      wch: Math.max(
        ...filteredDataSource.map((customer) => customer.user.lastname.length)
      ),
    },
    {
      wch: Math.max(
        ...filteredDataSource.map((customer) => customer.user.email.length)
      ),
    },
    {
      wch: Math.max(
        ...filteredDataSource.map((customer) => customer.totalAmount.length)
      ),
    },
    {
      wch: Math.max(
        ...filteredDataSource.map((customer) => customer.totalAmount.length)
      ),
    },
    // {
    //   wch:
    //     Math.max(...filteredDataSource.map((customer) => customer.postcode.length)) + 5
    // }
  ];
  const handleexcel = (orders) => {
    if (!orders || !orders.length) return [];

    return orders.map(order => ({
      order_id: order._id,
      Name: `${order.user.firstname} ${order.user.lastname}`,
      'Contact No.': order.user.mobilenumber,
      Email: order.user.email,
      Address: order?.address?.typeAddress,
      'Order Product': order.products.map(product => product?.name).join(', '),
      Amount: order.totalAmount,
      Kitchen: order.address?.kitchen ? 'Yes' : 'No', // Assuming kitchen field is boolean
      Date: moment(order.createdAt).format('DD-MM-YYYY'),
      Time: moment(order.createdAt).format('HH:mm'),
    }));
  };

  const menu = (
    <Menu onClick={handleDateRangeChange}>
      <Menu.Item key="today">Today</Menu.Item>
      <Menu.Item key="yesterday">Yesterday</Menu.Item>
      <Menu.Item key="last7days">Last 7 Days</Menu.Item>
      <Menu.Item key="last30days">Last 30 Days</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header />

      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-10 bg-light">
            <div className="main m-4">
              <div className="row p-3">
                <div className="col-md-6 my-2 col-12">
                  <p className="fs-3 fw-semibold text-start col-md-6 col-12">
                    {" "}
                    Order Overview
                  </p>
                </div>
                <div className="overview mt-3 px-3 py-3 bg-white col-md-12">
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 d-flex justify-content-between">
                    <div className="text-center mt-2 mb-2 col-md-4">

                      <input
                        type="text"
                        className="form-control border-0 bg-secondary-subtle text-secondary searchbox w-75"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="addon-wrapping"
                        value={searchText}
                        onChange={(e) => handleSearch(e.target.value)}
                      />

                    </div>
                    <div className="mt-2 col-md-4 text-end">
                      <TimePicker.RangePicker
                        use12Hours
                        format={'HH:mm'}
                        className="border-0 bg-secondary-subtle text-secondary searchbox p-2"
                        onChange={([start, end]) => {
                          handleStartTimeChange(start);
                          handleEndTimeChange(end);
                        }}
                        value={[startTime, endTime]}
                      />
                      <div className="mt-2 col-md-4 text-end">
                        <Dropdown overlay={menu}>
                          <Button>
                            Date Range <DownOutlined />
                          </Button>
                        </Dropdown>
                      </div>
                      {/* <Button onClick={handleFilterByTime}>Filter by Time</Button>
<Button onClick={clearFilterByTime}>Clear Filter</Button> */}
                    </div>
                    <div className="mt-2 col-md-4 text-end">
                      {filteredDataSource && (
                        <ExportCSV
                          csvData={handleexcel(filteredDataSource)}
                          fileName={"Order Book"}
                          title={"Order List"}
                          wscols={wscols}
                          headers={[
                            "order_id",
                            'Name',
                            'Contact No.',
                            'Email',
                            'Address',
                            'Order Product',
                            'Amount',
                            'Kitchen',
                            'Date',
                            'Time'
                          ]}
                          headerTitle={[
                            {
                              order_id: "#Order Id",
                              firstName: "First Name",
                              'Contact No.': "Contact No.",
                              email: "Email",
                              address: "Address",
                              'Order Product': 'Order Product',
                              price: "price",
                              Kitchen: "Kitchen",
                              Date: 'Date',
                              Time: "Time"
                            },
                          ]}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>



              <div className="row mx-1">
                <div className="table-responsive py-3 bg-white mt-3">
                  <Table
                    dataSource={filteredDataSource}
                    columns={columns}
                    pagination={{
                      pageSize: 10, // Set the number of items per page
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <Modal
        title="Order Details"
        visible={modalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={800}
        className="card-body rounded"
      >
        {modalContent}
      </Modal>
      {!getOrderResponse && <Loader />}
    </>
  );
};

export default Orders;
