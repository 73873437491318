import React, { useState, useRef, useEffect } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { signInWithEmailAndPassword } from "firebase/auth"; // Import Firebase Auth functions
import { auth } from "../firebase/firebase"; // Import initialized auth instance

const LoginModal = ({ visible, onClose }) => {
  const formRef = useRef();

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleEmailChange = (e) => {
    setLoginData((prevData) => ({ ...prevData, email: e.target.value }));
  };

  const handlePasswordChange = (e) => {
    setLoginData((prevData) => ({ ...prevData, password: e.target.value }));
  };

  const handleLogin = async (values) => {
    if (values.email && values.password) {
      try {
        // Use Firebase Auth to sign in
        const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
        const user = userCredential.user;
        localStorage.setItem("userId", user.uid);
        
        message.success("Login successful!", 5);
        onClose();
        window.location.href = "/";
      } catch (error) {
        message.error(error.message, 5); // Display Firebase Auth error message
      }
    } else {
      console.error("Please enter valid data for login");
    }
  };

  return (
    <div className="p-3">
      <h4 className="text-center font-weight-bold head-control mt-4">Login</h4>
      <Form requiredMark={false} onFinish={handleLogin} ref={formRef} initialValues={loginData}>
        <Form.Item
          name="email"
          label="Email"
          labelCol={{ span: 24 }}
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input
            className="form-control text-input"
            placeholder="Email"
            value={loginData.email}
            onChange={handleEmailChange}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          labelCol={{ span: 24 }}
          rules={[
            { required: true, message: "Please enter your password" },
            { min: 6, message: "Password must be at least 6 characters" },
          ]}
        >
          <Input
            className="form-control text-input custom-password-input"
            placeholder="Password"
            value={loginData.password}
            onChange={handlePasswordChange}
            style={{ backgroundColor: "transparent" }}
          />
        </Form.Item>

        <div className="mb-3">
          <p className="text-start p-texts mb-4 mt-2">Forgot Password?</p>
        </div>

        <div className="d-flex justify-content-center pt-2">
          <Button
            type="primary"
            className="btn button w-75 h-25"
            htmlType="submit"
          >
            Login
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default LoginModal;
